import { ChatAPI } from '@guuru/api-web';
import { ChatStep } from '@guuru/constants-common';
import {
  setCurrentChatChannel,
  setCurrentChatId,
  updateChatStep,
} from '@guuru/chat-web';
import updateChatConnectionError from './updateChatConnectionError';

export default async function (action) {
  const { partnerId, chatId, options } = action;
  const isChat = options?.channel !== 'form';
  setCurrentChatChannel(options?.channel);

  const {
    name,
    hasPredictions,
    chatWidgetRequestVisitorRole,
    chatWidgetRequiresName,
    chatWidgetRequiresEmail,
    chatWidgetShowWelcomeMessage,
    chatWidgetShowGreetingMessage,
    quickActions,
    quickActionsSettings,
  } = await ChatAPI.getChatSettings(partnerId);
  const {
    quickActionSkipIfAdviceQuestion,
  } = quickActionsSettings || {};

  const steps = [];

  if (!name) {
    steps.push(ChatStep.organizationSelection);
    updateChatConnectionError(false);
    updateChatStep();
    return ChatAPI.storeChatSteps(steps);
  }

  steps.push(ChatStep.chatInit);
  steps.push(ChatStep.chatLoad);
  steps.push(ChatStep.chatStart);
  steps.push(ChatStep.booting);

  if (!quickActionSkipIfAdviceQuestion && isChat && quickActions?.length > 0) {
    steps.push(ChatStep.quickActionSelection);
  }

  if (isChat && chatWidgetShowWelcomeMessage) {
    steps.push(ChatStep.showWelcomeMessage);
  }

  steps.push(ChatStep.chatQuestion);
  if (isChat && chatWidgetShowGreetingMessage) {
    steps.push(ChatStep.greeting);
  }

  if (hasPredictions) {
    steps.push(ChatStep.requestIntentSelection);
    steps.push(ChatStep.makePrediction);
  } else if (quickActionSkipIfAdviceQuestion && isChat) {
    steps.push(ChatStep.quickActionPredict);
    if (quickActions?.length > 0) {
      steps.push(ChatStep.quickActionSelection);
    }
  }

  steps.push(ChatStep.requestCategory);

  if (chatWidgetRequestVisitorRole) {
    steps.push(ChatStep.requestVisitorRole);
  }

  if (chatWidgetRequiresName) {
    steps.push(ChatStep.requestName);
  }

  if (chatWidgetRequiresEmail) {
    steps.push(ChatStep.requestEmail);
  }

  steps.push(ChatStep.connectingExpert);
  steps.push(ChatStep.chat);

  setCurrentChatId(chatId || ChatAPI.storeRetrieveChatId(partnerId));

  updateChatConnectionError(false);
  updateChatStep();

  ChatAPI.initStorageForNewRequest(partnerId, chatId, options);
  return ChatAPI.storeChatSteps(steps);
}
