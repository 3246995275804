import React from 'react';
import PropTypes from 'prop-types';
import { ChatAPI } from '@guuru/api-web';
import OpenDialogButton from './OpenDialogButton';

const EmojiPicker = React.lazy(() => import('./EmojiPicker'));

const ChatEmojiButton = function ({ onEmojiSelect, onToggle, opened }) {
  const locale = ChatAPI.storeRetrieveLocale();

  return (
    <React.Suspense fallback={<OpenDialogButton loading />}>
      <div className="chat-emoji-input">
        <OpenDialogButton
          isActive={opened}
          onClick={onToggle}
        />
        {opened && (
          <EmojiPicker
            dynamicWidth
            locale={locale}
            theme="light"
            previewPosition="none"
            skinTonePosition="none"
            onEmojiSelect={(emoji) => onEmojiSelect(emoji.native)}
            onClickOutside={onToggle}
          />
        )}
      </div>
    </React.Suspense>
  );
};

ChatEmojiButton.propTypes = {
  onEmojiSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default ChatEmojiButton;
