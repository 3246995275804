import React, { useEffect } from 'react';
import events from '@guuru/events-web';
import { Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../store/configureStore';

const store = configureStore();

const ChatLayout = function () {
  useEffect(() => {
    events.chatLoaded();
  }, []);

  return (
    <Provider store={store}>
      <Outlet />
    </Provider>
  );
};

export default ChatLayout;
