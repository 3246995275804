import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import events from '@guuru/events-web';
import { ChatAPI } from '@guuru/api-web';
import { useChatSettings } from '@guuru/chat-web';
import { Row, Text } from './style';

const partnersWithDPA = ['rossignol-com'];

const TermsAndConditions = function ({ visible = false }) {
  const { t } = useTranslation();
  const {
    data: { chatSettings: { id: partnerId } = {} } = {},
  } = useChatSettings();

  if (!visible) {
    return null;
  }

  const locale = ChatAPI.storeRetrieveLocale();
  const handleTermsAndConditionsClick = events.termsAndConditionsClick;
  const showPrivacyPolicy = !partnersWithDPA.includes(partnerId);

  return (
    <Text
      onClick={handleTermsAndConditionsClick}
      onKeyPress={handleTermsAndConditionsClick}
      role="presentation"
    >
      {t('chat:::userAgreement')}
      <Row>
        <a
          href={`https://www.guuru.com/${locale}/terms-of-service/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('chat:::termsAndConditions')}
        </a>
        {showPrivacyPolicy && (
          <>
            <span>{t('chat:::and')}</span>
            <a
              href={`https://www.guuru.com/${locale}/privacy-policy/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('chat:::privacyPolicy')}
            </a>
          </>
        )}
        {t('chat:::termsOfGuuruEnding')}
      </Row>
    </Text>
  );
};

TermsAndConditions.propTypes = {
  visible: PropTypes.bool,
};

export default TermsAndConditions;
