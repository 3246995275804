import { Sentry } from '@guuru/sentry-web';
import { ChatAPI } from '@guuru/api-web';
import {
  getCurrentChatStep,
  isRunningStep,
  storage,
  updateChatStep,
} from '@guuru/chat-web';
import log from '@guuru/logger-web';
import organizationSelection from './organizationSelection';
import requestName from './requestName';
import requestEmail from './requestEmail';
import connectingExpert from './connectingExpert';
import chatQuestion from './chatQuestion';
import makePrediction from './makePrediction';
import requestIntentSelection from './requestIntentSelection';
import showWelcomeMessage from './showWelcomeMessage';
import requestCategory from './requestCategory';
import requestVisitorRole from './requestVisitorRole';
import quickActionSelection from './quickActionSelection';
import greeting from './greeting';
import booting from './booting';
import chatStart from './chatStart';
import chatInit from './chatInit';
import chatLoad from './chatLoad';
import chatEnd from './chatEnd';
import quickActionPredict from './quickActionPredict';
import postHeightUpdate from '../chatActions/postHeightUpdate';

const { isBotTypingVar } = storage;

// Each step needs to implement 3 functions

// - validate: should be as lightheight as possible and only validate if
// it needs to call the run function.

// - render: is responsible to display the action to the user
// it can return some context that will change the nextStep
// * goToNextStep - continue the normal flow,
// use when step doesn't need to wait for response
// * goToStep - ability to skip some steps
// * nextStepOptions - pass some context to the next step only

// - processResponse: is responsible to gather any input given by the user
// and save it, its called right before the next action
// it can return some context that will change next step
// * stayInCurrentStep - block flow (currently used by quickActions in
// direct link)
// * goToStep - ability to skip some steps
// * nextStepOptions - pass some context to the next step only

const stepActions = {
  organizationSelection,
  chatInit,
  chatLoad,
  chatStart,
  chatEnd,
  quickActionSelection,
  showWelcomeMessage,
  chatQuestion,
  greeting,
  requestIntentSelection,
  makePrediction,
  requestCategory,
  requestVisitorRole,
  requestName,
  requestEmail,
  connectingExpert,
  booting,
  quickActionPredict,
};

const chooseStepToRun = function* (partnerId, nextStep, options = {}) {
  const canRun = yield stepActions[nextStep]?.validate(partnerId, options);
  let runResult = {};
  if (canRun) {
    isRunningStep(true);
    updateChatStep(nextStep);
    runResult = yield stepActions[nextStep].render(partnerId, options);
    postHeightUpdate(partnerId, nextStep);
    isRunningStep(false);
  }

  if (!canRun || runResult?.goToNextStep) {
    const chatSteps = ChatAPI.storeRetrieveChatSteps();
    const next = chatSteps[chatSteps.indexOf(nextStep) + 1];
    yield chooseStepToRun(partnerId, next, options);
    return;
  }

  const { goToStep, nextStepOptions } = runResult || {};
  if (goToStep) {
    yield chooseStepToRun(
      partnerId,
      goToStep,
      { ...options, ...nextStepOptions },
    );
  }
};

const runNextStep = function* ({ processArgs, action } = {}) {
  try {
    const currentStep = getCurrentChatStep();

    const partnerId = ChatAPI.storeRetrievePartnerId();
    const chatSteps = ChatAPI.storeRetrieveChatSteps();

    if (!currentStep) {
      yield chooseStepToRun(
        partnerId,
        chatSteps[0],
        { action },
      );
      return;
    }

    isBotTypingVar(true);

    const { stayInCurrentStep, goToStep, nextStepOptions } = (
      (yield stepActions[currentStep]?.processResponse(partnerId, processArgs))
      || {}
    );

    if (stayInCurrentStep) {
      postHeightUpdate(partnerId, currentStep);
      return;
    }

    const nextStep = goToStep || chatSteps[chatSteps.indexOf(currentStep) + 1];

    Sentry.addBreadcrumb({
      category: 'chat.steps',
      data: { nextStep, currentStep },
    });

    yield chooseStepToRun(
      partnerId,
      nextStep,
      {
        ...nextStepOptions,
        action,
        skipAutomation: processArgs?.skipAutomation,
      },
    );

    isBotTypingVar(false);
  } catch (error) {
    log.error(error);
  }
};

export default runNextStep;
