import { ChatAPI } from '@guuru/api-web';
import { ExpertsPriority } from '@guuru/expert-common';
import createAndTransferChat from '../../chatActions/createAndTransferChat';

const isProductAdvise = (automationBehavior) => (
  automationBehavior?.label === 'get_info_product_advise'
);

const hasAlreadyGuurusOnlyPriority = () => {
  const priority = ChatAPI.storeRetrieveChatPriority();
  return (priority === ExpertsPriority.guurusOnly.value);
};

const handleSmartRouting = (partnerId, automationBehavior) => {
  let priority = automationBehavior?.subscriptionPriority;
  if (hasAlreadyGuurusOnlyPriority() && isProductAdvise(automationBehavior)) {
    priority = ExpertsPriority.guurusOnly.value;
  }
  ChatAPI.storeChatPriority(priority);
  return { goToNextStep: true };
};

export default function* (automationBehavior, { partnerId }) {
  if (automationBehavior?.subscriptionPriority === 'transfer') {
    yield createAndTransferChat(partnerId, {
      targetId: automationBehavior?.subscriptionTarget,
      handover: automationBehavior.label === 'form_fallback',
    });
    return { goToNextStep: false };
  }

  return handleSmartRouting(partnerId, automationBehavior);
}
