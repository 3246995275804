import { ChatAPI } from '@guuru/api-web';
import { heightUpdatedEvent } from '@guuru/chat-events-web';
import { ChatStep } from '@guuru/constants-common';
import { getCurrentChatStep } from '@guuru/chat-web';

let HEIGHT = 0;

const IgnoreSteps = [
  ChatStep.chatQuestion,
  ChatStep.booting,
  ChatStep.chatInit,
  ChatStep.chatStart,
  ChatStep.chatLoad,
];

const getElement = (selector) => global.document
  .getElementsByClassName(selector)[0];

const getElementHeight = (selector) => (
  getElement(selector)?.scrollHeight || 0
);

const getComponentHeight = () => {
  const headerHeight = getElementHeight('chat-header-wrapper');
  const contentSectionHeight = getElementHeight('chat-content-section');
  const askAnotherQuestionHeight = getElementHeight('chat-message-bar');
  let height = headerHeight + askAnotherQuestionHeight;

  if (contentSectionHeight < 350) {
    height += 350;
  } else {
    height += contentSectionHeight + 30;
  }

  return height;
};

export default function (partnerId, step = getCurrentChatStep()) {
  const channel = ChatAPI.storeRetrieveChannel(partnerId);

  if (channel !== 'form') return;

  let prevHeight = HEIGHT;

  if (IgnoreSteps.includes(step)) {
    HEIGHT = 0;
    heightUpdatedEvent(0);
    return;
  }

  HEIGHT = getComponentHeight();

  const startTimeout = (timeout) => {
    setTimeout(() => {
      prevHeight = HEIGHT;
      HEIGHT = getComponentHeight();
      if (prevHeight < HEIGHT || step === ChatStep.connectingExpert) {
        heightUpdatedEvent(HEIGHT);
      }
    }, timeout);
  };

  startTimeout(700);
  startTimeout(2000);

  if (prevHeight === HEIGHT) return;

  if (prevHeight < HEIGHT || step === ChatStep.connectingExpert) {
    heightUpdatedEvent(HEIGHT);
  }
}
