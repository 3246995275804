import { gql } from '@apollo/client';

export default gql`
  query GetPersistedExpertProfile($partnerId: ID!, $expertId: ID!) {
    community(id: $partnerId) {
      id
      expert(expertId: $expertId, partnerId: $partnerId) {
        id
        bio
        photoUrl
        name
        joinedAt
        rating
        bestFeedbackComments {
          comment
        }
        conversationLevel
        mentions {
          id
          summary
          url
          headline
        }
      }
    }
  }
`;
